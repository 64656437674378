import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Step, StepButton, Stepper, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { t, use } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED_OPTION, INLINE_SELECT_OPTION, ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL, THEME_BUTTON } from "../../constants/style";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PersonalInfoTab from "../../components/student-registration-component/personal-info-tab";
import AdmissionQualificationTab from "../../components/student-registration-component/admission-qualification-tab";
import CreditTransferTab from "../../components/student-registration-component/credit-transfer-tab";
import SummaryTab from "../../components/student-registration-component/summary-tab";
import { ContentType, DefaultAdmissionQuaDataObj, DefaultCreditTransferDataObj, DefaultEnrolQualificationObj, DefaultPersonalInfoDataObj, EnrolFormTabs, EnrollForm, SchoolLevel, SectionLocation, YesOrNo } from "../../constants/registration-options";
import ContractTab from "../../components/student-registration-component/contract-tab";
import EnrolQualificationTab from "../../components/student-registration-component/enrol-qualification.tab";
import { navigate } from "gatsby";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const RegistrationFormPage = observer(({ location }) => {
  const { rootStore, traineeshipStore, teacherStore, groupStore } = useStores();
  const [enrolFormTabs, setEnrolFormTabs] = useState<any>(EnrolFormTabs);
  const [personalInfoData, setPersonalInfoData] = useState<any>({});
  const [admissionQuaData, setAdmissionQuaData] = useState<any>({});
  const [creditTransferData, setCreditTransferData] = useState<any>({});
  const [enrolQualificationData, setEnrolQualificationData] = useState<any>({});
  const [summaryData, setSummaryData] = useState<EnrollForm>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showGoBackModel, setShowGoBackModal] = useState<boolean>(false);
  // Determine is update or create
  const [step, setStep] = useState<number>(0);
  const [onHoldStep, setOnHoldStep] = useState<number>(-1);
  const [pageLoc, setPageLoc] = useState<number>(0);
  const [previewMode, setPreviewMode] = useState<boolean>(true);
  const finalStep = enrolFormTabs.indexOf(enrolFormTabs.find(t => t.title == "Summary"));
  const [refreshDisplay, setRefreshDisplay] = useState<any>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    console.log(location.state);

    if (!location.state || !location.state.formId || location.state.formId == 0)
      navigate("/traineeship/overview");
    else {
      setPreviewMode(location.state.previewMode != null ? location.state.previewMode : previewMode);
      if (location.state.previewMode == true) setStep(finalStep);
      traineeshipStore.getFormById(location.state.formId)
        .then((formRes) => {
          setIsSubmitted(formRes.isSubmitted);
          // initiate step state
          let personalInfo: any = EnrolFormTabs[0];
          personalInfo.updatedBy = formRes.personalInformationUpdateBy ? formRes.personalInformationUpdateBy : "";
          personalInfo.updatedTime = formRes.personalInformationUpdateTime ? new Date(formRes.personalInformationUpdateTime).toLocaleString() : "";
          let admissionQua: any = EnrolFormTabs[1];
          admissionQua.updatedBy = formRes.admissionQualificationUpdateBy ? formRes.admissionQualificationUpdateBy : "";
          admissionQua.updatedTime = formRes.admissionQualificationUpdateTime ? new Date(formRes.admissionQualificationUpdateTime).toLocaleString() : "";
          let creditTransfer: any = EnrolFormTabs[2];
          creditTransfer.updatedBy = formRes.creditTransferUpdateBy ? formRes.creditTransferUpdateBy : "";
          creditTransfer.updatedTime = formRes.creditTransferUpdateTime ? new Date(formRes.creditTransferUpdateTime).toLocaleString() : "";
          let enrolQualification: any = EnrolFormTabs[3];
          enrolQualification.updatedBy = formRes.enrollQualificationUpdateBy ? formRes.enrollQualificationUpdateBy : "";
          enrolQualification.updatedTime = formRes.enrollQualificationUpdateTime ? new Date(formRes.enrollQualificationUpdateTime).toLocaleString() : "";
          // initiate data to each step
          DefaultPersonalInfoDataObj.firstName = location.state.firstName;
          DefaultPersonalInfoDataObj.lastName = location.state.lastName;
          DefaultPersonalInfoDataObj.middleName = location.state.middleName;
          DefaultPersonalInfoDataObj.email = location.state.email;
          DefaultPersonalInfoDataObj.birthDate = location.state.birthDate;
          DefaultPersonalInfoDataObj.uniqueStudentId = location.state.usi;

          let tempPersonalInfo = formRes.personalInformation ?
            JSON.parse(formRes.personalInformation) : DefaultPersonalInfoDataObj;
          let tempAdmissionQuaData = formRes.admissionQualification ?
            JSON.parse(formRes.admissionQualification) : DefaultAdmissionQuaDataObj;
          let tempCreditTransferData = formRes.creditTransfer ?
            JSON.parse(formRes.creditTransfer) : DefaultCreditTransferDataObj;
          let tempEnrolQuaData = formRes.enrollQualification ?
            JSON.parse(formRes.enrollQualification) : DefaultEnrolQualificationObj
          setPersonalInfoData({...tempPersonalInfo, studentId: formRes.traineeId});
          setAdmissionQuaData(tempAdmissionQuaData);
          setCreditTransferData(tempCreditTransferData);
          setEnrolQualificationData(tempEnrolQuaData);
          setSummaryData({
            personalInformation: tempPersonalInfo,
            admissionQualifications: tempAdmissionQuaData,
            creditTransfer: tempCreditTransferData,
            enrolQualification: tempEnrolQuaData,
          });
          // initate completed tab
          EnrolFormTabs[0].completed = formRes.personalInformationUpdateTime != null ? true : false;
          EnrolFormTabs[1].completed = formRes.admissionQualificationUpdateTime != null ? true : false;
          EnrolFormTabs[2].completed = formRes.creditTransferUpdateTime != null ? true : false;
          EnrolFormTabs[3].completed = formRes.enrollQualificationUpdateTime != null ? true : false;
          setRefreshDisplay([]);
        })
    }
  }, []);

  const handleShowModal = (activeStep: number) => {
    if (previewMode) setStep(activeStep)
    else {
      if (step == finalStep) {
        setSummaryData({
          personalInformation: personalInfoData,
          admissionQualifications: admissionQuaData,
          creditTransfer: creditTransferData,
          enrolQualification: enrolQualificationData,
        });
        setStep(activeStep);
      }
      else {
        setShowModal(true);
        setOnHoldStep(activeStep);
      }
    }
  }

  const onCloseModal = (complete: boolean) => {
    if (complete) setStep(onHoldStep);
    setOnHoldStep(-1);
    setShowModal(false);
  }

  const onUpdateFormDataByTab = (paramInfo: any, action: "Next" | "Back" | "Stay") => {
    console.log("paramInfo", paramInfo);
    let tempPersonalData: any = personalInfoData;
    let tempAdmissionData: any = admissionQuaData;
    let tempCreditData: any = creditTransferData;
    let tempEnrolQuaData: any = enrolQualificationData;
    let completedData: any = EnrolFormTabs[step];
    traineeshipStore.updateForm({
      jsonStr: JSON.stringify(paramInfo),
      contentType: step == 0 ? ContentType.PERSONAL_INFO :
        step == 1 ? ContentType.ADMISSION_QUALIFICATION :
          step == 2 ? ContentType.CREDIT_TRANSFER :
            ContentType.ENROLL_QUALIFICATION,
      formId: location.state && location.state.formId,
    }).then((formRes) => {
      rootStore.notify("Form step updated", "success");
      switch (step) {
        case 0: setPersonalInfoData({...paramInfo, studentId: formRes.traineeId});
          tempPersonalData = paramInfo;
          completedData.updatedBy = formRes.personalInformationUpdateBy ? formRes.personalInformationUpdateBy : "";
          completedData.updatedTime = formRes.personalInformationUpdateTime ? new Date(formRes.personalInformationUpdateTime).toLocaleString() : "";
          break;
        case 1: setAdmissionQuaData(paramInfo);
          tempAdmissionData = paramInfo;
          if (paramInfo.completedEduactionLevel) {
            let tempCreditData = creditTransferData;
            // auto fill in if the user already said he completed year 12 education
            if (paramInfo.completedEduactionLevel == SchoolLevel.find(sl => sl.id == 1)?.label)
              tempCreditData.completedYearTwelve.completed = YesOrNo.YES;
            else tempCreditData.completedYearTwelve.completed = YesOrNo.NO;
            setCreditTransferData(tempCreditData);
            console.log(tempCreditData);

            setRefreshDisplay([]);
          }
          completedData.updatedBy = formRes.admissionQualificationUpdateBy ? formRes.admissionQualificationUpdateBy : "";
          completedData.updatedTime = formRes.admissionQualificationUpdateTime ? new Date(formRes.admissionQualificationUpdateTime).toLocaleString() : "";
          break;
        case 2: setCreditTransferData(paramInfo);
          tempCreditData = paramInfo;
          completedData.updatedBy = formRes.creditTransferUpdateBy ? formRes.creditTransferUpdateBy : "";
          completedData.updatedTime = formRes.creditTransferUpdateTime ? new Date(formRes.creditTransferUpdateTime).toLocaleString() : "";
          break;
        case 3: setEnrolQualificationData(paramInfo);
          tempEnrolQuaData = paramInfo;
          completedData.updatedBy = formRes.enrollQualificationUpdateBy ? formRes.enrollQualificationUpdateBy : "";
          completedData.updatedTime = formRes.enrollQualificationUpdateTime ? new Date(formRes.enrollQualificationUpdateTime).toLocaleString() : "";
          break;
      }
      setSummaryData({
        personalInformation: tempPersonalData,
        admissionQualifications: tempAdmissionData,
        creditTransfer: tempCreditData,
        enrolQualification: tempEnrolQuaData,
      });

      if (action == "Next") setStep(step + 1);
      else if (action == "Back") setStep(step - 1);
      setRefreshDisplay([]);
    }).catch(() => rootStore.notify("Step update failed! Please check your internet connection or contact network admin", "error"));
  }

  const goPreviousStep = (paramInfo: any) => {
    // if (!previewMode) {
    onUpdateFormDataByTab(paramInfo, "Back");
    EnrolFormTabs[step].completed = true;
    // }
    setRefreshDisplay([]);
    window.scrollTo(0, 0);
  }

  const onCompleteToNextStep = (paramInfo: any, saveOnly?: boolean) => {
    // if (!previewMode) {
    onUpdateFormDataByTab(paramInfo, saveOnly ? "Stay" : "Next");
    EnrolFormTabs[step].completed = true;
    // } 
    if (!saveOnly) {
      if ((step + 1) == 4 && EnrolFormTabs.filter(e => e.completed == false).length > 2) {
        rootStore.notify("Please complete all steps before approaching to Summary", "warning");
      } else {
        // setStep(step + 1);
        window.scrollTo(0, 0);
      }
    }
    setRefreshDisplay([]);
  }

  const onNavigate = (location: SectionLocation) => {
    switch (location) {
      case SectionLocation.ADMISSION:
        setStep(1);
        window.scrollTo(0, 0);
        break;
      case SectionLocation.CREDIT:
        setStep(2);
        window.scrollTo(0, 0);
        break;
      case SectionLocation.ENROL_QUA:
        setStep(3);
        window.scrollTo(0, 0);
        break;
      default:
        setStep(0);
        setPageLoc(location);
        break;
    }
  }

  const onSubmitForm = () => {
    if (location.state) {
      traineeshipStore.submitEnrollForm(location.state.formId)
        .then(() => {
          rootStore.notify("Form submitted!", 'success');
          navigate("/traineeship/overview", {
            state: { inputPage: location.state.inputPage, traineeId: location.state.traineeId }
          });
        }).catch(() => rootStore.notify('Submit failed! Please check your internet or contact network admin!', 'error'));
      EnrolFormTabs[finalStep].completed = true;
      EnrolFormTabs[finalStep].updatedTime = " ";
      EnrolFormTabs[finalStep].updatedBy = "All Pre-Compulsory Steps Completed";
    }
  }

  return (
    <ContentLayout
      pageName={`Registration Form`}
      pageHeading={`Enrolment Form ${isSubmitted ? '(Submitted)' : '(Not Submitted Yet)'}`}
      breadCrumb={[]}
      showHeadingButton={false}
      enableChildNav={false}
    >
      {/* if form data is loaded show loading ui */}
      {!location.state || traineeshipStore.loading || !traineeshipStore.formInfo ? <>
        <Box sx={ITEM_PERFECT_INLINED} className="justify-center mb-8">
          <Skeleton variant="circular" width={80} height={80} sx={{ marginX: 2 }} />
          <Skeleton variant="circular" width={80} height={80} sx={{ marginX: 2 }} />
          <Skeleton variant="circular" width={80} height={80} sx={{ marginX: 2 }} />
          <Skeleton variant="circular" width={80} height={80} sx={{ marginX: 2 }} />
          <Skeleton variant="circular" width={80} height={80} sx={{ marginX: 2 }} />
        </Box>
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <Skeleton height={50} />
        <div className="flex justify-end">
          <Skeleton height={80} width={120} sx={{ marginRight: 2 }} />
          <Skeleton height={80} width={120} />
        </div>
      </>
        : <>
          <button className="bg-white p-3 rounded-lg hover:text-highlight" onClick={() => {
            if (!previewMode) setShowGoBackModal(true);
            else {
              if (location.state && location.state.inputPage && location.state.traineeId)
                navigate("/traineeship/overview", {
                  state: { inputPage: location.state.inputPage, traineeId: location.state.traineeId }
                });
            }
          }}>
            <Box sx={ITEM_PERFECT_INLINED}>
              <ArrowBackIosNewIcon />
              <Typography marginLeft={1}>Go Back</Typography>
            </Box>
          </button>
          <Stepper sx={{ marginTop: 2 }} activeStep={step} nonLinear alternativeLabel>
            {EnrolFormTabs.map((tab: any, index) => (
              <Step key={tab.title} completed={index == finalStep && isSubmitted ? true : tab.completed}>
                {/* if any of tabs before summary tab is not completed, summary tab is disabled */}
                <StepButton disabled={index == finalStep && EnrolFormTabs.filter(t => t.completed == false).length > 1}
                  onClick={() => handleShowModal(index)}>
                  {tab.title}
                  {tab.updatedTime !== "" && tab.updatedBy !== "" ? <>
                    <Box><Typography variant="caption">Updated by <u>{tab.updatedBy}</u></Typography></Box>
                    <Box style={{ marginTop: -5 }}><Typography variant="caption"><u>{tab.updatedTime}</u></Typography></Box>
                  </> : index !== finalStep &&
                  <Box><Typography variant="caption">Incomplete</Typography></Box>}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ py: 2 }}>
            {step == 0 ? <PersonalInfoTab pageLocation={pageLoc} prefillData={personalInfoData} onCompleteToNextStep={onCompleteToNextStep} previewMode={false} />
              : step == 1 ? <AdmissionQualificationTab prefillData={admissionQuaData} goPreviousStep={goPreviousStep} onCompleteToNextStep={onCompleteToNextStep} previewMode={false} />
                : step == 2 ? <CreditTransferTab prefillData={creditTransferData} goPreviousStep={goPreviousStep} onCompleteToNextStep={onCompleteToNextStep} previewMode={false} />
                  : step == 3 ? <EnrolQualificationTab prefillData={enrolQualificationData} goPreviousStep={goPreviousStep} onCompleteToNextStep={onCompleteToNextStep} previewMode={false} />
                    // : step == 4 ? <ContractTab prefillData={creditTransferData} goPreviousStep={goPreviousStep} onCompleteToNextStep={onCompleteToNextStep} />
                    : step == finalStep &&
                    <SummaryTab
                      formId={location.state.formId}
                      uploadedFileList={traineeshipStore.formInfo.traineeshipDocuments}
                      onNavigate={onNavigate}
                      summaryData={summaryData}
                      onSubmitForm={onSubmitForm}
                      previewMode={previewMode}
                    />}
          </Box>
        </>}

      <VmModal
        openModal={showModal || showGoBackModel}
        onClose={() => {
          setShowModal(false);
          setShowGoBackModal(false);
        }}
        title={"Warning"}
        icon={<Box className="text-highlight" marginRight={1}><ErrorOutlineIcon color="inherit" /></Box>}
        showButton={false}
        width={400}
      >
        <Typography variant="body1" textAlign="center" marginBottom={2}>Any unsaved inputs in this step will lost!</Typography>
        <div className="text-right p-4">
          <Button
            variant="outlined"
            onClick={() => {
              if (!showGoBackModel) onCloseModal(true);
              else {
                if (location.state && location.state.inputPage && location.state.traineeId)
                  navigate("/traineeship/overview", {
                    state: { inputPage: location.state.inputPage, traineeId: location.state.traineeId }
                  });
              }
            }}
            color="success"
          >
            YES, PROCEED
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setShowModal(false);
              setShowGoBackModal(false);
            }}
            sx={{ marginLeft: 2 }}
            color="error"
          >
            {t('CANCEL')}
          </Button>
        </div>
      </VmModal>
    </ContentLayout>
  )
});

export default RegistrationFormPage;
