import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react"
import { IconButton, CircularProgress, Skeleton, TextField, ThemeProvider, Typography, Select, MenuItem, FormGroup, Checkbox, FormControlLabel, SelectChangeEvent, Button, Modal, FormControl, InputLabel, Autocomplete } from "@mui/material";
import { observer } from "mobx-react";
import { THEME_BUTTON, THEME_COLOR, THEME_HIGHLIGHT } from "../../constants/style";
import { ITEM_PERFECT_INLINED } from "../../constants/style";
import { t } from "i18next";
import SectionLayout from "./section-layout";
import VmModal from "../shared-modal";
import { Link } from "gatsby";
import { BooleanCheck, YesOrNo } from "../../constants/registration-options";
import FormNavButton from "./form-nav-button";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck } from "../../constants/options";

const EnrolQulifaicationTab = observer(({
  prefillData, goPreviousStep = (paramInfo: any) => { }, onCompleteToNextStep = (paramInfo: any, saveOnly: boolean) => { }, previewMode }) => {
  const { groupStore, academicStore } = useStores();
  const [subjectId, setSubjectId] = useState<string>("0");
  const [groupId, setGroupId] = useState<string>("0");
  const [orgId, setOrgId] = useState<string>("0");
  const [qualificationName, setQualificationName] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");
  const [orgName, setOrgName] = useState<string>("");
  const [refreshDisplay, setRefreshDisplay] = useState<any>([]);
  const [showError, setShowError] = useState<boolean>(false);


  const onChangeSubjectAutoComplete = (subjectCode: string) => {
    if (academicStore.qualificationList.data.length > 0) {
      let subject = academicStore.qualificationList.data.find(s => subjectCode === s.code + " " + s.name);
      if (subject) {
        setSubjectId(subject.id);
        setQualificationName(subjectCode);
      }
    }
  }

  const onChangeGroupAutoComplete = (groupName: string) => {
    if (groupStore.groupList.data.length > 0) {
      let group = groupStore.groupList.data.find(s => groupName === s.name);
      if (group) {
        setGroupName(groupName);
        setGroupId(group.id);
        setOrgId("0");
        // groupStore.getOrganisationListByGroupId(group.id, 0, 0);
      }
    }
  }

  useEffect(() => {
    if (+groupId > 0) {
      console.log("paramInfo", +groupId);
      const delayDebouncer = setTimeout(() => {
        groupStore.getOrganisationListByGroupId(+groupId, 0, 0)
          .then((res) => {
            if (prefillData) {
              setOrgId(prefillData.orgId);
              setOrgId("0");
              setOrgName(res.data.find(o => o.id == prefillData.orgId) ?
                res.data.find(o => o.id == prefillData.orgId).name : "");
              setRefreshDisplay([]);
            }
          });
      }, 300);
      return () => clearTimeout(delayDebouncer);
    }
  }, [groupId]);

  const onChangeOrgAutoComplete = (orgName: string) => {
    if (groupStore.organisationList.data.length > 0) {
      let org = groupStore.organisationList.data.find(s => orgName === s.name);
      if (org) {
        setOrgId(org.id);
        setOrgName(orgName);
      }
    }
  }

  const onCheckErrorExist = () => {
    if (+subjectId == 0) return true;
    if (+groupId == 0) return true;
    if (+orgId == 0) return true;
    return false;
  }

  useEffect(() => {
    if (ApiDataResponseListLengthCheck(ApiDataListType.DOMAIN) == 0 ||
      academicStore.qualificationList != ApiDataResponseListLengthCheck(ApiDataListType.DOMAIN)) {
      academicStore.getQualificationList(0, 0);
    }
    if (ApiDataResponseListLengthCheck(ApiDataListType.GROUP) == 0 ||
      groupStore.getGroupList != ApiDataResponseListLengthCheck(ApiDataListType.GROUP)) {
      groupStore.getGroupList(0, 0);
    }
  }, []);

  useEffect(() => {
    if (prefillData) {
      setSubjectId(prefillData.qualificationId);
      setQualificationName(prefillData.qualificationName);
      setGroupId(prefillData.groupId);
      setGroupName(prefillData.groupName);
    }
  }, [prefillData]);

  const onCheckErrors = (goNext: boolean, saveOnly: boolean) => {
    setShowError(true);
    if (!onCheckErrorExist()) {
      let req = {
        qualificationId: subjectId,
        groupId,
        orgId,
        groupName,
        qualificationName,
        orgName,
      }
      if (goNext) onCompleteToNextStep(req, saveOnly);
      else goPreviousStep(req);
    }
  }

  return (
    <>
      <SectionLayout title="Enrol Qualification" showHelp={false}>
        <Box sx={ITEM_PERFECT_INLINED}>
          <Typography marginRight={2} variant="body1" fontWeight="bold">
            Please pick a qualification from the given options that you want to enrol:
          </Typography>
        </Box>

        <Box sx={ITEM_PERFECT_INLINED} marginTop={2}>
          {academicStore.loadingSubject ? <Skeleton width={400} height={80} />
            : <Autocomplete
              disablePortal
              id="combo-box-demo"
              size="medium"
              value={academicStore.qualificationList.data.find(s => s.id == subjectId) ?
                academicStore.qualificationList.data.find(s => s.id == subjectId).code + " " + academicStore.qualificationList.data.find(s => s.id == subjectId).name : ""}
              options={academicStore.qualificationList.data.map((s) => ({ label: s.code + " " + s.name, id: s.id }))}
              disabled={previewMode}
              sx={{ width: 400 }}
              onInputChange={(event, newInputValue) => onChangeSubjectAutoComplete(newInputValue)}
              renderInput={(params) => <TextField  {...params} label={t('DOMAIN')} error={showError && +subjectId == 0} />}
            />}

          <Link to="/academic/domain-module" className="ml-4">
            <Button variant="outlined" size="large" disabled={previewMode}>Add Qualification</Button>
          </Link>
        </Box>

        <Box marginY={4}>
          <Typography marginBottom={2} variant="body1" fontWeight="bold">
            Please enrol to an organisation from the given options:
          </Typography>

          <div className="grid grid-cols-2 gap-4">
            {groupStore.loading ? <Skeleton width={"100%"} height={80} />
              : <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={groupStore.groupList.data.find(s => s.id == groupId) ? groupStore.groupList.data.find(s => s.id == groupId).name : ""}
                options={groupStore.groupList.data.map((s) => ({ label: s.name, id: s.id }))}
                disabled={previewMode}
                sx={{ width: '100%', marginBottom: 2 }}
                onInputChange={(event, newInputValue) => onChangeGroupAutoComplete(newInputValue)}
                renderInput={(params) =>
                  <TextField  {...params} label={t('GROUP')} error={showError && +groupId == 0} />}
              />}

            {groupId !== "" && +groupId > 0 &&
              <>
                {groupStore.loading || groupStore.loadingOrg ? <Skeleton width={"100%"} />
                  : <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={groupStore.organisationList.data.find(s => s.id == orgId) ? groupStore.organisationList.data.find(s => s.id == orgId).name : ""}
                    options={groupStore.organisationList.data.map((s) => ({ label: s.name, id: s.id }))}
                    disabled={previewMode}
                    sx={{ width: '100%', marginBottom: 2 }}
                    onInputChange={(event, newInputValue) => onChangeOrgAutoComplete(newInputValue)}
                    renderInput={(params) =>
                      <TextField  {...params} label={t('ORGANISATION')} error={showError && +orgId == 0} />}
                  />}
              </>}
          </div>
        </Box>
      </SectionLayout>

      <FormNavButton
        showNext={true}
        showBack={true}
        onClickNext={() => onCheckErrors(true, false)}
        onClickBack={() => onCheckErrors(false, false)}
        onClickSave={() => onCheckErrors(true, true)}
        previewMode={previewMode}
      />
    </>
  )
});

export default EnrolQulifaicationTab;
